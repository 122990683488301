import React from "react";
import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";
import LessonLayout from "~/layouts/lesson";

const Page = () => {
  return (
    <LessonLayout
      current_step={7}
      lesson={"Lesson 5"}
      color={"hs"}
      total_count={7}
      module_title="Putting Your Rubric to Work"
    >
      <ContentWrapper>
        <H2 underline>Step 7</H2>

        <p>During the course of these lessons, you have learned and practiced skills related to analyzing health-related information you find online and in other types of media.</p>
        <p>These skills are important for health literacy. Literacy does not mean just being able to read the words. An important part of health literacy is the ability to obtain, analyze, and understand information. In fact, these skills are also part of science literacy and media literacy. By completing the rubric, you are practicing communicating information, which is also a part of these three literacies.</p>
        <p>To give you more information about why health literacy is important in our lives, your teacher will share some more information about why people need to have these skills.</p>
        <p>As you hear this new information, try to think about ways that you may use your health-literacy skills in your own life.</p>
      </ContentWrapper>
    </LessonLayout>
  )
}

export default Page;

